const mainStyle = {

      colouredButton: {
        backgroundColor:"#19A78E",
        fontSize: "16px;",
        color: "#fff;",
        fontWeight: "700;",
        textDecoration: "none"
      },

      clearButton: {
        fontSize: "16px;",
        color: "#374657;",
        fontWeight: "700;",
        textDecoration: "none"
      }
  };
  export default mainStyle;
  