import mainStyle from "../mainStyle.js";

const homeStyle = {
    mainStyle,
    tempContainer:{
      textAlign: "center;",
      marginBottom:"80px",
    },
  }

  export default homeStyle;
  