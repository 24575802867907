import mainStyle from "../mainStyle.js";

const headerStyle = {
  mainStyle,
  root: {
    flexGrow: 1,
  },
  Header: {
    backgroundColor:"#fff",
    backgroundImage:"url('/images/header-background.svg');",
    backgroundRepeat: "no-repeat;",
    backgroundPosition: "center;",
    backgroundSize:"cover;",
    borderBottom:"1px solid #8F9297", 
    paddingTop: "30px;",
    paddingBottom: "20px;",
    
  },
  ToolBar:{
    padding:0,
  },
  SiteIcon: {
    backgroundColor:"#19A78E",
    color:"#fff",
    padding:"10px",
    marginRight:"15px",
    borderRadius:"22px",
    //marginRight: theme.spacing(2),
  },
  title: {
    //flexGrow: 1,
    fontSize: "36px;",
    color: "#374657;",
    fontWeight: "700;",
    fontFamily: "'Titillium Web', sans-serif;",
    margin:0,
    marginRight:"20px",
  },
  Space:{
    flexGrow: 1,
  },
  loginButton:mainStyle.clearButton,
  SubmitIssueButton:mainStyle.colouredButton,
  ProfileButton:{
    fontSize: "30px;",
    color: "#374657;",
    fontWeight: "700;",
    textDecoration: "none",
    marginRight:" 15px;"

  },
  SearchBox:{
    border: "1px solid #19A78E;",
    paddingLeft: "20px;",
    paddingRight: "0px;",
    borderRadius: "50px;",
    background:"#fff",
    marginRight:" 15px;"
  },
  Searchinput:{

  },
  SearchButton:{
    color:"#19A78E",
  },
  MenuItem:{
    color:"#8f9297",
    padding:"20px",
    borderBottomLeftRadius:"10px",
    borderBottomRightRadius:"10px",
    paddingTop: "100px;",
    marginTop: "-80px;",
    marginRight:"10px",
    fontWeight: "700;",
    paddingBottom: "10px;",
  },
  MenuItemSelected:{
    background: "#19A78E;",
    color:"#fff",
    padding:"20px",
    borderBottomLeftRadius:"10px",
    borderBottomRightRadius:"10px",
    paddingTop: "100px;",
    marginTop: "-80px;",
    marginRight:"10px",
    fontWeight: "700;",
    paddingBottom: "10px;",
  }
};
  
  export default headerStyle;
  