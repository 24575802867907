import  authReducer  from './authReducer'
import  devicesReducer  from './devicesReducer'
import  deviceTypesReducer  from './deviceTypesReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    devices: devicesReducer,
    deviceTypes: deviceTypesReducer
});

export default rootReducer;