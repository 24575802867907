import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
//import  Firebaseui  from 'firebaseui'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCOcHti3aBG3kLZFfANMcOcpE7pt5wGe6s",
  authDomain: "fix-my-stuff.firebaseapp.com",
  databaseURL: "https://fix-my-stuff.firebaseio.com",
  projectId: "fix-my-stuff",
  storageBucket: "fix-my-stuff.appspot.com",
  messagingSenderId: "95753942031",
  appId: "1:95753942031:web:0d3f57137f6a1abb3d9f7a",
  measurementId: "G-WBJ1802F0L"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.storage()

export default firebase;