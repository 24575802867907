import mainStyle from "../mainStyle.js";

const siteLogoStyle = {
  mainStyle,
  root:{
    marginRight: "10px;",
    marginBottom: "-10px;"
}
};
  
  export default siteLogoStyle;
   