//import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = {

  footer: {
    backgroundColor: "#19A78E",
    color: "#fff",
    paddingTop:"50px",
    paddingBottom:"20px",
    
    backgroundImage:"url('/images/footer-background.svg');",
    backgroundRepeat: "no-repeat;",
    backgroundPosition: "center;",
    backgroundSize:"cover;",
  },
  copyright:
  {
    borderTop:"1px solid #fff",
    padding:" 20px 0",
    color: "#fff",
  },
  ToolBar:{
    padding:0,
    marginBottom:"30px"
  },
  SiteIcon: {
    backgroundColor:"#fff",
    color:"#19A78E",
    padding:"10px",
    marginRight:"15px",
    borderRadius:"22px",

  },
  title: {
    flexGrow: 1,
    fontSize: "36px;",
    color: "#374657;",
    fontWeight: "700;",
    fontFamily: "'Titillium Web', sans-serif;",
    margin:0,
    color: "#fff",
  },

};
export default footerStyle;
