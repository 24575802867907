import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import  firebase  from 'firebase'
import SiteLogo from "./SiteLogo"
import {loginShow,Userlogout} from '../store/actions/authActions'

import { makeStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu'; 
import MemoryIcon from '@material-ui/icons/Memory';
import classNames from "classnames";

import styles from "../assets/components/headerStyle.js";
const useStyles = makeStyles(styles);


const Header = (props) => {
    const {user,loginShow,Userlogout} = props
    
    const classes = useStyles();

   // if(user != null)
    
        return (
            <div className={classes.root}>
                <header position="static" className={classes.Header}>
                    <Container>
                        <Toolbar  className={classes.ToolBar}>
                            <SiteLogo />
                            <h1 className={classes.title} >
                                FIX MY STUFF
                            </h1>
                        </Toolbar>
                    </Container>
                </header>
            </div>
        );
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginShow: () => dispatch(loginShow()),
        Userlogout: () => dispatch(Userlogout())
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Header)