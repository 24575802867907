import React from 'react';
import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'

import Home from './pages/Home'



function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route component={Home}/> {/* Add recent stuff */}

          </Switch>
          <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
