import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import  firebase  from 'firebase'
import SiteLogo from "./SiteLogo"

import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import classNames from "classnames";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MemoryIcon from '@material-ui/icons/Memory';
import MenuIcon from '@material-ui/icons/Menu'; 

import styles from "../assets/components/FooterStyle.js";
const useStyles = makeStyles(styles);


const Footer = (props) => {

    const {user} = props

    const classes = useStyles();
    //var user = firebase.auth().currentUser;

    return (
        <footer className={classes.footer}>
            <Container>
                    <Toolbar  className={classes.ToolBar}>
                        <SiteLogo   white={true} />
                        <h1 className={classes.title} >
                            FIX MY STUFF
                        </h1>
                    </Toolbar>
                <div className={classes.copyright}>
                    Copyright © 2020 <NavLink to="https://fixmystuff.info/">fixmystuff.info</NavLink>. All rights reserved.
                </div>
            </Container>
        </footer>
    )
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Footer)