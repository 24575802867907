
//import firebase from 'firebase';
const initState ={
    loginShow: false 
}

const authReducer = (state = initState, action) => {
    if(action.type === 'LOGIN_SHOW')
        return {
            ...state,
            loginShow: true
        }
    if(action.type === 'LOGIN_HIDE')
        return {
            ...state,
            loginShow: false
        }
    return state;
}

export default authReducer;