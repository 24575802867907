import React, {Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
import { firestore } from 'firebase'
import { getFirebase } from 'react-redux-firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';


import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';

import styles from "../assets/views/homeStyle.js";
const useStyles = makeStyles(styles);

const Index = (props) => {

    const classes = useStyles();
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                window.location.href = "https://fixmystuff.info/";
                return false;
            },
            uiShown: function() {
            }
        }
    };

    var user = firebase.auth().currentUser;
    var login = (<div><h2>Log in and get notified</h2>
        <p>Get notified when this platform is released. Sign up using the buttons below.</p>
        <div id="firebaseui-auth-container">
            
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
        </div></div>);
    if(user !=null)
    login = (<div><h2>Thank you</h2><p>you will get notified when the platform is released.</p></div>)

       // const { brokenStuff,Devices,fixedStuff,hackedStuff }=props;
        return (
            <main>
                <Container className={classes.tempContainer}>
                    <h1>Under Constrution, Coming Soon</h1>
                    <p>This platform will to help fix stuff. You will be about to upload your problem and other users can help you fix your problem. you will know how to fix your stuff and where to get the parts and tools to fix it.</p>
                    <p>Currently looking for web designers and graphic designed for this platform.</p>
                    <p>If you want help with fixing your stuff or if you can help with this platform, you can email me at: tinkersprojects@gmail.com.</p>
                    {login}
                </Container>
            </main>
        );
}

const mapStateToProps = (state) => {

    console.log("state")
    console.log(state)

    return {
        brokenStuff: state.firestore.ordered.brokenStuff,
        Devices: state.firestore.ordered.Devices,
        fixedStuff: state.firestore.ordered.fixedStuff,
        hackedStuff: state.firestore.ordered.hackedStuff
    }
}

export default /*compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'Stuff', storeAs: 'brokenStuff', limit:8, where:[["status","==","Broken"]]},
        { collection: 'Devices', storeAs: 'Devices', limit:8},
        { collection: 'Stuff', storeAs: 'hackedStuff', limit:8, where:[["status","==","Hacked"]]},
        { collection: 'Stuff', storeAs: 'fixedStuff', limit:8, where:[["status","==","Fixed"]]},
    ])
)*/(Index);