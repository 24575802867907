
import { getFirebase } from 'react-redux-firebase'

export const loginShow = () =>
{
    return(dispatch, getState) => {
        console.log("LOGIN_SHOW")
        dispatch({type:'LOGIN_SHOW'})   
    }
}

export const loginHide = () =>
{
    return(dispatch, getState) => {
        console.log("LOGIN_HIDE")
        dispatch({type:'LOGIN_HIDE'})   
    }
}

export const Userlogout = () =>
{
    return(dispatch, getState, ) => {
        const firebase = getFirebase();
        firebase.auth().signOut();
        window.location.reload();
    }
}

